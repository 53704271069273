html,
body {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}
